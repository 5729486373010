import { mean, sum, sumBy } from "lodash";
import {
  distributeProportionally,
  getUnitsConversionFactor,
  roundUnit,
} from "../../../../lib/formats";
import {
  InventoryStatus,
  UnitType,
  WorkOrderCostCenterFragment,
  WorkOrderStatus,
} from "../../../../lib/graphql";
import { WorkOrderToolBuilder, WorkOrderVariant } from "./tools";
import { WorkOrderCostCenter } from "./costCenters";

const PRECISION = 10000;

export class WorkOrderInputBuilder extends WorkOrderToolBuilder {
  protected get field() {
    return "inputs";
  }

  getTotalConsumed(variant: WorkOrderVariant) {
    const costCenters = this.getCostCenters();

    return sumBy(
      costCenters,
      (cc) =>
        cc.variants.find((v) => v.variantId === variant.variant.id)
          ?.consumedAmount || 0
    );
  }

  onInit() {
    super.onInit();

    this.init();
  }

  init(inputs = this.entity.inputs, costCenters = this.entity.costCenters) {
    inputs.forEach((i) => {
      if (!i.applicationUnitId)
        i.applicationUnitId = i.applicationUnit?.id || "";
      if (!i.returnedAmount) i.returnedAmount = 0;

      const dosages: number[] = [];
      costCenters.forEach((cc) => {
        const ccv = cc.variants.find((v) => v.variantId === i.variant.id);
        if (!ccv) {
          cc.variants.push({
            id: "",
            variantId: i.variant.id,
            amount: 0,
            dosage: 0,
            consumedAmount: 0,
          });
        } else {
          if (
            ccv.consumedAmount == undefined &&
            this.entity.status === WorkOrderStatus.InProgress
          ) {
            ccv.consumedAmount = ccv.amount;
          }

          if (ccv.dosage == undefined) {
            const applicationFactor = this.applicationFactor(i, cc);
            ccv.dosage = roundUnit(ccv.amount / applicationFactor, PRECISION);
          }

          dosages.push(ccv?.dosage);
        }
      });

      if (i.dosage == null) {
        i.dosage = roundUnit(
          i.applicationUnit == null ? sum(dosages) : mean(dosages),
          PRECISION
        );
      }
    });
  }

  initInputs(byProgress?: boolean) {
    const inputs = this.get();
    const costCenters = this.getCostCenters();

    this.init(inputs, costCenters);

    if (byProgress) {
      inputs.forEach((i) => this.recalculateByProgress(i));
    }
  }

  isValidConsumption() {
    const inputs = this.get();
    for (const input of inputs) {
      if (
        roundUnit(
          (input.returnedAmount || 0) + this.getTotalConsumed(input),
          PRECISION
        ) != input.totalAmount
      ) {
        return false;
      }
    }
    return true;
  }

  getRequiredDosage(variant: WorkOrderVariant) {
    const totalProgress = this.builder.allowProgress
      ? this.builder.costCenters.getTotalProgress()
      : this.builder.costCenters.getTotalArea();

    return roundUnit(variant.totalAmount / totalProgress, PRECISION);
  }

  getActualDosage(variant: WorkOrderVariant) {
    const totalProgress = this.builder.allowProgress
      ? this.builder.costCenters.getTotalProgress()
      : this.builder.costCenters.getTotalArea();
    const consumed = this.getTotalConsumed(variant);
    return roundUnit(consumed / totalProgress, PRECISION);
  }

  onRemove(input: WorkOrderVariant) {
    super.onRemove(input);

    if (input.id) return; // remove ccv on backend

    const costCenters = this.getCostCenters();
    costCenters.forEach(({ variants }) => {
      const index = variants.findIndex((v) => v.variantId === input.variant.id);
      if (index >= 0) variants.splice(index, 1);
    });
  }

  onDayGoalChanged() {
    const inputs = this.get();
    inputs.forEach((_, index) => this.calculateAmounts(index));
  }

  private applicationFactor(
    input: WorkOrderVariant,
    costCenter: WorkOrderCostCenter
  ) {
    if (!input.applicationUnit) return 1;

    if (input.applicationUnit.abbr === "pl")
      return this.applicationFactorPerPlant(costCenter);

    const workOrder = this.builder ? this.builder.value : this.entity;
    if (
      input.applicationUnit.unitType !== UnitType.Volume ||
      !workOrder.waterUsage
    ) {
      return this.applicationFactorPerUnit(costCenter);
    }

    // by water usage
    const waterUsage = workOrder.waterUsage;
    const total = sumBy(workOrder.costCenters, (cc) =>
      this.applicationFactorPerUnit(cc)
    );

    const factor =
      total > 0
        ? roundUnit(
            this.applicationFactorPerUnit(costCenter) / total,
            PRECISION
          )
        : workOrder.costCenters.length;

    const conversionFactor = getUnitsConversionFactor(
      waterUsage.waterUnit,
      input.applicationUnit
    );

    return (
      waterUsage.tanksRequired *
      waterUsage.tankCapacity *
      factor *
      conversionFactor
    );
  }

  get allowProgress() {
    return this.entity.activity.progressUnit.unitType !== UnitType.Time;
  }

  private applicationFactorPerUnit(costCenter: WorkOrderCostCenter) {
    return this.allowProgress
      ? costCenter.dayGoal
      : costCenter.cropField
      ? costCenter.cropField.effectiveArea
      : 0;
  }

  private applicationFactorPerPlant(costCenter: WorkOrderCostCenter) {
    return !costCenter.cropField
      ? 0
      : this.allowProgress
      ? costCenter.cropField.plantDensity * costCenter.dayGoal
      : costCenter.cropField.effectivePlants;
  }

  calculateAmountByCostCenterDosage(
    costCenterIndex: number,
    inputIndex: number,
    val?: number | null
  ) {
    const costCenter = this.builder.costCenters.getBy(costCenterIndex);
    const input = this.getBy(inputIndex);
    const applicationFactor = this.applicationFactor(input, costCenter);
    const value = roundUnit((val || 0) * applicationFactor, PRECISION);

    this.form.setFields([
      {
        name: this.builder.costCenters.variantFieldName(
          costCenterIndex,
          input,
          "amount"
        ),
        value,
      },
    ]);

    this.recalculateAmountByCostCenters(inputIndex);
  }

  private recalculateAmountByCostCenters(inputIndex: number) {
    const input = this.getBy(inputIndex);
    const costCenters = this.getCostCenters();

    const dosages: number[] = [];
    let total = 0;
    costCenters.forEach(({ variants }) => {
      const ccv = variants.find((v) => v.variantId === input.variant.id);

      if (ccv) {
        total += ccv.amount;
        dosages.push(ccv.dosage || 0);
      }
    });

    this.form.setFields([
      {
        name: ["inputs", inputIndex, "totalAmount"],
        value: total,
      },
    ]);

    const avgDosage =
      input.applicationUnit == null ? sum(dosages) : mean(dosages);

    this.form.setFields([
      {
        name: ["inputs", inputIndex, "dosage"],
        value: roundUnit(avgDosage, PRECISION),
      },
    ]);
  }

  private calculateAmountByTotal(input: WorkOrderVariant, val?: number | null) {
    const costCenters = this.getCostCenters();

    if (val == undefined || val === null || costCenters.length === 0) return;

    const distribution = distributeProportionally(
      val,
      costCenters.map((cc) => this.applicationFactor(input, cc)),
      PRECISION
    );

    costCenters.forEach((cc, ccIndex) => {
      const ccvIndex = cc.variants.findIndex(
        (ccv) => ccv.variantId === input.variant.id
      );

      this.form.setFields([
        {
          name: ["costCenters", ccIndex, "variants", ccvIndex, "amount"],
          value: distribution[ccIndex],
        },
        {
          name: ["costCenters", ccIndex, "variants", ccvIndex, "dosage"],
          value: distribution[ccIndex],
        },
      ]);
    });
  }

  private calculateByAvgDosage(
    input: WorkOrderVariant,
    index: number,
    val?: number | null
  ) {
    const costCenters = this.getCostCenters(false);

    if (val == undefined || val === null || costCenters.length === 0) return;

    costCenters.forEach((cc, ccIndex) => {
      if (cc._destroy) return;

      const ccvIndex = cc.variants.findIndex(
        (ccv) => ccv.variantId === input.variant.id
      );

      this.form.setFields([
        {
          name: ["costCenters", ccIndex, "variants", ccvIndex, "dosage"],
          value: roundUnit(val, PRECISION),
        },
      ]);

      this.calculateAmountByCostCenterDosage(ccIndex, index, val);
    });
  }

  calculateAmounts(index: number) {
    const input = this.getBy(index);
    const value = input.dosage;

    if (input.applicationUnit == null) {
      this.form.setFields([
        {
          name: ["inputs", index, "totalAmount"],
          value,
        },
      ]);
      this.calculateAmountByTotal(input, value);
    } else {
      this.calculateByAvgDosage(input, index, value);
    }
  }

  recalculateByProgress(input: WorkOrderVariant, updateState = false) {
    if (this.builder.inventoryRequestDisabled) {
      input.returnedAmount = 0;
    }

    const consumed = roundUnit(
      input.totalAmount - (input.returnedAmount || 0),
      PRECISION
    );
    const costCenters = this.getCostCenters();

    const distribution = distributeProportionally(
      consumed,
      costCenters.map((cc) =>
        this.builder.allowProgress ? cc.totalProgress : 1
      ),
      PRECISION
    );

    costCenters.forEach((cc, index) => {
      const ccvIndex = cc.variants.findIndex(
        (ccv) => ccv.variantId === input.variant.id
      );
      if (ccvIndex >= 0) {
        const ccv = cc.variants[ccvIndex];
        ccv.consumedAmount = distribution[index] || 0;
        // if ccv is new, it has 0 amount
        if (ccv.amount == 0) ccv.amount = ccv.consumedAmount || 0;

        if (updateState) {
          this.form.setFields([
            {
              name: [
                "costCenters",
                index,
                "variants",
                ccvIndex,
                "consumedAmount",
              ],
              value: distribution[index],
            },
          ]);
        }
      }
    });
  }

  recalculateAmounts(
    inputIndex: number,
    byProgress: boolean,
    costCenterIndex?: number
  ) {
    const input = this.get(false)[inputIndex];

    if (byProgress) {
      return this.recalculateByProgress(input, true);
    }

    if (costCenterIndex == undefined) return;

    const costCenters = this.getCostCenters();

    const precision = 10000;
    const totalConsumed = roundUnit(
      sumBy(
        costCenters,
        (cc) => (this.getVariant(cc, input)?.consumedAmount || 0) * precision
      ),
      PRECISION
    );

    if (this.builder.inventoryRequestDisabled || !input.id) {
      this.form.setFields([
        {
          name: ["inputs", inputIndex, "totalAmount"],
          value: roundUnit(totalConsumed / precision, PRECISION),
        },
      ]);
    } else {
      const totalAmount = roundUnit(input.totalAmount * precision, PRECISION);
      if (totalConsumed <= totalAmount) {
        this.form.setFields([
          {
            name: ["inputs", inputIndex, "returnedAmount"],
            value: roundUnit(
              (input.totalAmount * precision - totalConsumed) / precision,
              PRECISION
            ),
          },
        ]);
      }
    }
  }

  updateInput(inputIndex: number, updateStatus = true) {
    const input = this.get(false)[inputIndex];
    const costCenters = this.getCostCenters();

    const distribution = distributeProportionally(
      input.totalAmount,
      costCenters.map((cf) => cf.totalProgress || cf.dayGoal),
      PRECISION
    );

    costCenters.forEach((cc, ccIndex) => {
      const ccvIndex = cc.variants.findIndex(
        (ccv) => ccv.variantId === input.variant.id
      );

      this.form.setFields([
        {
          name: ["costCenters", ccIndex, "variants", ccvIndex, "amount"],
          value: distribution[ccIndex],
        },
        {
          name: [
            "costCenters",
            ccIndex,
            "variants",
            ccvIndex,
            "consumedAmount",
          ],
          value: distribution[ccIndex],
        },
      ]);
    });

    if (updateStatus) {
      this.form.setFields([
        {
          name: ["inputs", inputIndex, "status"],
          value: InventoryStatus.Requesting,
        },
      ]);
    }
  }

  recalculateAll() {
    const inputs = this.get();

    inputs.forEach((i) => {
      this.calculateAmountByTotal(i, i.totalAmount);
    });
  }

  private getVariant(
    costCenter: WorkOrderCostCenterFragment,
    variant: WorkOrderVariant
  ) {
    return costCenter.variants.find(
      (ccv) => ccv.variantId === variant.variant.id
    );
  }
}
