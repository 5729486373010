import { Col, Divider, Row, Typography } from "antd";
import { Form, InputNumber, RuleBuilder, Rules, SelectField } from "../../form";
import { FormattedMessage } from "react-intl";
import { UnitSelect } from "../../units";
import {
  InventoryStatus,
  ItemKind,
  RecipeVariant,
  UnitType,
} from "../../../lib/graphql";
import { useCallback, useContext, useEffect } from "react";
import { WorkOrderContext } from "./WorkOrderContext";
import {
  formatNumber,
  formatTags,
  formatUnitValue,
  formatUnitValueConversion,
  formatVariantLink,
  shouldUpdate,
  tagRender,
  variantSelectDropdown,
} from "../../../lib/formats";
import {
  useCurrentUser,
  useEquipmentOptions,
  useUnitOptions,
  useWaterSourceOptions,
} from "../../../lib/hooks";
import { Space, TableInput } from "../../shared";
import { useStockVariantWithRecipesOptions } from "../../../lib/hooks/inventory/variants";
import { RateInput } from "./WorkOrderInputs";
import { WorkOrderVariant } from "./builder/tools";
import { filterColumns } from "../../../lib/utils";
import {
  CurrentStockTooltip,
  InventoryRequestWarehouseCell,
} from "./inventory";
import Icon from "@ant-design/icons";
import { flatMap } from "lodash";

export function WorkOrderSpraying() {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { currentTenant } = useCurrentUser();
  const formItemProps = { labelCol: { span: 8 }, wrapperCol: { span: 14 } };
  const { unitOptions } = useUnitOptions({ types: [UnitType.Volume] });

  const recalculate = useCallback(
    () => builder.waterUsage.recalculate(),
    [builder]
  );

  useEffect(() => {
    const unit = unitOptions?.find((u) => u.primary)?.unit;
    if (!unit) return;

    builder.waterUsage.init(unit);
  }, [unitOptions, builder]);

  return (
    <Form.Item
      noStyle
      shouldUpdate={shouldUpdate(
        "waterUsage",
        "costCenters",
        ["waterUsage", "waterUnit"],
        ["waterUsage", "outputUnit"],
        ["waterUsage", "applicationUnit"]
      )}
    >
      {() =>
        builder.waterUsage.value && (
          <Row style={{ marginTop: 8 }}>
            {builder.isDataIntake ? (
              <Col xs={24} xl={8}>
                <Form.Item
                  name={["waterUsage", "tanksUsed"]}
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/tank.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.tanksUsed"
                        defaultMessage="Tanks Used"
                      />
                    </Space>
                  }
                  rules={[
                    Rules.gtZero,
                    RuleBuilder.ltEq(workOrder.waterUsage?.tanksRequired),
                  ]}
                  required={false}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 6 }}
                  extra={
                    <Typography.Text
                      style={{ fontSize: "75%" }}
                      type="secondary"
                    >
                      <FormattedMessage
                        id="required"
                        defaultMessage="Required"
                      />
                      : {formatNumber(builder.waterUsage.value.tanksRequired)}
                    </Typography.Text>
                  }
                >
                  {builder.isReadonly ? (
                    formatNumber(workOrder.waterUsage?.tanksUsed || 0)
                  ) : (
                    <InputNumber
                      step={0.1}
                      onChange={() => builder.waterUsage.calculateConsumption()}
                    />
                  )}
                </Form.Item>
              </Col>
            ) : (
              <Col xs={24} xl={10}>
                <Form.Item
                  name={["waterUsage", "tankCapacity"]}
                  label={
                    <FormattedMessage
                      id="workOrders.spraying.tankCapacity"
                      defaultMessage="Tank Capacity"
                    />
                  }
                  required={!builder.isDataIntake}
                  rules={[Rules.gtZero]}
                  {...formItemProps}
                >
                  {builder.isReadonly || builder.isDataIntake ? (
                    formatUnitValue(
                      workOrder.waterUsage?.tankCapacity,
                      workOrder.waterUsage?.waterUnit
                    )
                  ) : (
                    <InputNumber
                      min={0}
                      addonAfter={
                        <UnitSelect.FormItem
                          name={["waterUsage", "waterUnit"]}
                          unitTypes={[UnitType.Volume]}
                        />
                      }
                      onChange={recalculate}
                    />
                  )}
                </Form.Item>

                <Form.Item
                  name={["waterUsage", "outputRate"]}
                  label={
                    <FormattedMessage
                      id="workOrders.spraying.outputRate"
                      defaultMessage="Sprayer Output"
                    />
                  }
                  required={!builder.isDataIntake}
                  rules={[Rules.gtZero]}
                  {...formItemProps}
                >
                  {builder.isReadonly || builder.isDataIntake ? (
                    formatUnitValue(
                      workOrder.waterUsage?.outputRate,
                      workOrder.waterUsage?.outputUnit,
                      workOrder.waterUsage?.applicationUnit.abbr
                    )
                  ) : (
                    <InputNumber
                      addonAfter={
                        <UnitSelect.FormItem
                          name={["waterUsage", "outputUnit"]}
                          unitTypes={[UnitType.Volume]}
                          onUnitChange={recalculate}
                        />
                      }
                      per={
                        <UnitSelect.FormItem
                          name={["waterUsage", "applicationUnit"]}
                          labelRenderer={(value) => (
                            <FormattedMessage id="per" values={{ value }} />
                          )}
                          unitTypes={[UnitType.Area]}
                          onUnitChange={recalculate}
                        />
                      }
                      onChange={recalculate}
                    />
                  )}
                </Form.Item>
              </Col>
            )}

            {builder.isDataIntake ? (
              <Col xs={24} lg={12} xl={8}>
                <Form.Item
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/water.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.waterUsed"
                        defaultMessage="Water Used"
                      />
                    </Space>
                  }
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 6 }}
                  extra={
                    <Typography.Text
                      style={{ fontSize: "75%" }}
                      type="secondary"
                    >
                      <FormattedMessage
                        id="required"
                        defaultMessage="Required"
                      />
                      :{" "}
                      {formatUnitValue(
                        builder.waterUsage.waterRequired,
                        builder.waterUsage.value.waterUnit
                      )}
                    </Typography.Text>
                  }
                >
                  {formatUnitValue(
                    builder.waterUsage.value.waterAmount,
                    builder.waterUsage.value.waterUnit
                  )}
                </Form.Item>
              </Col>
            ) : (
              <Col xs={24} lg={12} xl={7}>
                <Form.Item
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/area.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.coverageArea"
                        defaultMessage="Coverage Area"
                      />
                    </Space>
                  }
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 5 }}
                >
                  {formatUnitValue(
                    builder.waterUsage.coverageArea,
                    workOrder.locality.areaUnit
                  )}
                </Form.Item>

                <Form.Item
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/spray.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.sprayRequired"
                        defaultMessage="Spray Required"
                      />
                    </Space>
                  }
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 5 }}
                >
                  {formatUnitValue(
                    builder.waterUsage.sprayRequired,
                    builder.waterUsage.value.waterUnit
                  )}
                </Form.Item>
              </Col>
            )}

            {builder.isDataIntake ? (
              <Col xs={24} lg={12} xl={7}>
                <Form.Item
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/spray.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.sprayUsed"
                        defaultMessage="Spray Used"
                      />
                    </Space>
                  }
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 5 }}
                  extra={
                    <Typography.Text
                      style={{ fontSize: "75%" }}
                      type="secondary"
                    >
                      <FormattedMessage
                        id="required"
                        defaultMessage="Required"
                      />
                      :{" "}
                      {formatUnitValue(
                        builder.waterUsage.sprayRequired,
                        builder.waterUsage.value.waterUnit
                      )}
                    </Typography.Text>
                  }
                >
                  {formatUnitValue(
                    builder.waterUsage.sprayUsed,
                    builder.waterUsage.value.waterUnit
                  )}
                </Form.Item>
              </Col>
            ) : (
              <Col xs={24} lg={12} xl={7}>
                <Form.Item
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/tank.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.tanksRequired"
                        defaultMessage="Tanks Required"
                      />
                    </Space>
                  }
                  name={["waterUsage", "tanksRequired"]}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 5 }}
                >
                  <>{formatNumber(builder.waterUsage.value?.tanksRequired)}</>
                </Form.Item>

                <Form.Item
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/water.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.waterAmount"
                        defaultMessage="Water"
                      />
                    </Space>
                  }
                  required={false}
                  name={["waterUsage", "waterAmount"]}
                  rules={[Rules.gtZero]}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 5 }}
                >
                  {formatUnitValue(
                    builder.waterUsage.value.waterAmount,
                    builder.waterUsage.value.waterUnit
                  )}
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <TableInput
                name="inputs"
                rules={[Rules.required]}
                dataSource={workOrder.inputs}
                tableProps={{
                  bordered: true,
                }}
                rowKey={(f) => f.variant.id}
                disabled={builder.isReadonly || builder.isDataIntake}
                onRemove={(v) => builder.waterUsage.onRemoveInput(v)}
                allowBulkRemove
                tableSelectProps={{
                  mode: "multiple",
                  showGroups: true,
                  groupsSorter: () => 0,
                  optionsHook: useStockVariantWithRecipesOptions,
                  optionsHookParams: {
                    variables: {
                      localityId: workOrder.locality.id,
                      date: workOrder.documentDate,
                      fetchEquipment: true,
                      filter: { itemKind: [ItemKind.Consumable] },
                    },
                  },
                  placeholder: (
                    <FormattedMessage
                      id="select.variants"
                      defaultMessage="variants"
                    />
                  ),
                  dropdownRender: variantSelectDropdown(),
                  entityById: (_, { variant, recipe }) => {
                    if (variant) {
                      return {
                        id: "",
                        variant,
                        totalAmount: 0,
                        dosage: 0,
                        status: InventoryStatus.NotRequested,
                        unit: variant.variationUnit,
                        applicationUnit: builder.waterUsage.value.waterUnit,
                        applicationUnitId:
                          builder.waterUsage.value.waterUnit.id,
                      };
                    }

                    if (recipe) {
                      return recipe.variants.map((v: RecipeVariant) => ({
                        id: "",
                        variant: v.variant,
                        totalAmount: 0,
                        dosage: v.rate,
                        status: InventoryStatus.NotRequested,
                        unit: v.unit,
                        applicationUnit: v.applicationUnit,
                        applicationUnitId: v.applicationUnit?.id,
                      }));
                    }
                  },
                  afterAdd: (inputs) =>
                    builder.waterUsage.onAddInput(flatMap(inputs)),
                }}
                addSorter={(a, b) => builder.inputs.sorter(a, b)}
                columns={filterColumns<WorkOrderVariant>([
                  {
                    title: <FormattedMessage id="variants.entityName" />,
                    dataIndex: "variant",
                    render: (_, v) => formatVariantLink(v.variant),
                  },
                  !builder.isDataIntake &&
                    currentTenant.features.inventoryStock && {
                      width: "1rem",
                      render: (_, v) => (
                        <CurrentStockTooltip
                          variant={v}
                          date={workOrder.documentDate}
                          localityId={workOrder.locality.id}
                        />
                      ),
                    },
                  !builder.isDataIntake && {
                    title: <FormattedMessage id="dosage" />,
                    dataIndex: "dosage",
                    width: "22rem",
                    align: "center",
                    render: (_, _v, index) => (
                      <RateInput
                        name={["inputs", index, "dosage"]}
                        inputIndex={index}
                        onChange={() =>
                          builder.waterUsage.onInputChanged(index)
                        }
                      />
                    ),
                  },
                  {
                    title: builder.isDataIntake ? (
                      <FormattedMessage id="workOrders.requested" />
                    ) : (
                      <FormattedMessage id="workOrders.totalRequested" />
                    ),
                    dataIndex: "totalAmount",
                    width: "10rem",
                    align: "center",
                    render: (_, v, index) => (
                      <Form.Field name={["inputs", index]}>
                        {(input: WorkOrderVariant) => (
                          <>
                            <div>
                              {formatUnitValueConversion(input.totalAmount, {
                                unit: input.unit,
                                conversionUnit: v.variant.variationUnit,
                                hideConversion: true,
                              })}
                            </div>
                            {builder.isDataIntake && (
                              <Typography.Text
                                type="secondary"
                                style={{ fontSize: "75%" }}
                              >
                                {formatUnitValue(
                                  v.dosage,
                                  v.unit,
                                  v.applicationUnit?.abbr
                                )}
                                <br />
                                {formatUnitValueConversion(
                                  builder.inputs.getRequiredDosage(v),
                                  {
                                    unit: v.unit,
                                    conversionUnit: v.variant.variationUnit,
                                    hideConversion: true,
                                    per: builder.allowProgress
                                      ? workOrder.activity.progressUnit.abbr
                                      : workOrder.locality.areaUnit.abbr,
                                  }
                                )}
                              </Typography.Text>
                            )}
                          </>
                        )}
                      </Form.Field>
                    ),
                  },
                  builder.isDataIntake && {
                    title: <FormattedMessage id="workOrders.consumed" />,
                    align: "center",
                    render: (_, v) => (
                      <>
                        <div>
                          {formatUnitValueConversion(
                            builder.inputs.getTotalConsumed(v),
                            {
                              unit: v.unit,
                              conversionUnit: v.variant.variationUnit,
                              hideConversion: true,
                            }
                          )}
                        </div>
                        <Typography.Text
                          type="secondary"
                          style={{ fontSize: "75%" }}
                        >
                          {formatUnitValueConversion(
                            builder.inputs.getActualDosage(v),
                            {
                              unit: v.unit,
                              conversionUnit: v.variant.variationUnit,
                              hideConversion: true,
                              per: builder.allowProgress
                                ? workOrder.activity.progressUnit.abbr
                                : workOrder.locality.areaUnit.abbr,
                            }
                          )}
                        </Typography.Text>
                      </>
                    ),
                  },
                  !builder.isDataIntake && {
                    title: (
                      <FormattedMessage
                        id="workOrders.spraying.perTank"
                        defaultMessage="Per Tank"
                      />
                    ),
                    width: "10rem",
                    align: "center",
                    render: (_, _v, index) => (
                      <Form.Field name={["inputs", index]}>
                        {(input: WorkOrderVariant) => (
                          <>
                            <Typography.Text>
                              {formatUnitValue(
                                input.totalAmount /
                                  builder.waterUsage.value.tanksRequired,
                                input.unit
                              )}
                            </Typography.Text>
                          </>
                        )}
                      </Form.Field>
                    ),
                  },
                  {
                    title: (
                      <FormattedMessage
                        id={
                          !currentTenant.inventoryRequestEnabled
                            ? "inventoryRequests.sourceWarehouse"
                            : "workOrders.inventoryStatus"
                        }
                      />
                    ),
                    width: 150,
                    align: "center",
                    render: (_, _v, index) => (
                      <InventoryRequestWarehouseCell name={["inputs", index]} />
                    ),
                  },
                ])}
              />
            </Col>

            <Col span={24}>
              <Divider />
            </Col>

            <Col xs={24} md={16}>
              <Form.Item
                name={"equipmentIds"}
                label={<FormattedMessage id="equipment" defaultMessage="PPE" />}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                {builder.isReadonly ? (
                  <>{formatTags(workOrder.equipment.map((s) => s.name))}</>
                ) : (
                  <SelectField
                    optionsHook={useEquipmentOptions}
                    mode="multiple"
                  />
                )}
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                name={["waterUsage", "waterSourceIds"]}
                label={
                  <FormattedMessage
                    id="workOrders.spraying.waterSources"
                    defaultMessage="Water Sources"
                  />
                }
                {...formItemProps}
              >
                {builder.isReadonly ? (
                  <>
                    {formatTags(
                      workOrder.waterUsage?.waterSources.map((s) => s.name),
                      "blue"
                    )}
                  </>
                ) : (
                  <SelectField
                    optionsHook={useWaterSourceOptions}
                    mode="multiple"
                    tagRender={(props) =>
                      tagRender({ color: "blue", ...props })
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        )
      }
    </Form.Item>
  );
}
