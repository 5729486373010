import { ArrowDownOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { sumBy } from "lodash";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { WorkOrderContext } from ".";
import {
  formatActivity,
  formatDate,
  formatDetailsLink,
  formatInfoTooltip,
  formatPlaceholder,
  formatUnitValue,
  formatVariantLink,
  shouldUpdateBy,
  variantSelectDropdown,
} from "../../../lib/formats";
import {
  ActivityMetricScope,
  DateRangeType,
  InventoryStatus,
  ItemKind,
  LocationType,
  WorkOrderInfoFragment,
  WorkOrderType,
} from "../../../lib/graphql";
import { useStockVariantOptions } from "../../../lib/hooks/inventory/variants";
import { Form, InputNumber, RuleBuilder, Rules, SelectField } from "../../form";
import { TableInput } from "../../shared";
import { WorkOrderVariant } from "./builder/tools";
import { WorkOrderOutput } from "./builder/outputs";
import { useWorkOrderOutputOptions } from "../../../lib/hooks";
import routes from "../../../lib/routes";

const entityById =
  (props: any) =>
  (
    _: any,
    {
      variant,
      workOrderVariant,
      workOrder,
    }: {
      variant: any;
      workOrderVariant?: WorkOrderVariant;
      workOrder?: WorkOrderInfoFragment;
    }
  ) => {
    if (variant) {
      return {
        id: "",
        variant,
        totalAmount: 0,
        status: InventoryStatus.NotRequested,
        unit: variant.variationUnit,
        stock: variant.stock,
        workOrderVariant,
        workOrder,
        sources: [],
        ...props,
      } as WorkOrderVariant;
    }
  };

function WarehouseOutputs({ warehouseId }: { warehouseId?: string | null }) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const stockVariables = {
    localityId: builder.workOrder.locality.id,
    locationId: warehouseId,
    locationType: LocationType.Warehouse,
    date: workOrder.documentDate,
  };

  const { options, search } = useStockVariantOptions({
    variables: {
      ...stockVariables,
      filter: { itemKind: [ItemKind.Producible] },
    },
  });

  useEffect(() => builder.outputs.metrics.initMetricFields(), [builder]);

  const [selectorConfig, setSelectorConfig] = useState<
    "item" | "harvestOrder" | "processingOrder"
  >("item");

  const searchByItems = selectorConfig == "item";

  const { options: workOrderOptions, search: workOrderSearch } =
    useWorkOrderOutputOptions({
      fetchPolicy: "cache-and-network",
      variables: {
        ...stockVariables,
        pageSize: 100,
        filter: {
          outputWarehouseId: warehouseId,
          localityId: [workOrder.locality.id],
          cropCycleId: workOrder.cropCycle
            ? [workOrder.cropCycle.id]
            : undefined,
          workOrderType: [
            selectorConfig == "harvestOrder"
              ? WorkOrderType.Harvest
              : WorkOrderType.Processing,
          ],
          documentDate: {
            rangeType: DateRangeType.Range,
            range: [
              new Date(2024, 0, 23).toISOString(),
              workOrder.documentDate,
            ], // start from feature date
          },
        },
      },
    });

  return (
    <Form.Item
      noStyle
      shouldUpdate={shouldUpdateBy(
        (val) => val.processingInputs.filter(Form.undestroyed).length,
        (val) => val.processingOutputs.filter(Form.undestroyed).length
      )}
    >
      {({ setFieldValue }) => {
        const inputs = builder.outputs.getProcessingInputs();
        const outputs = builder.outputs.getProcessingOutputs();

        const addedVariantIds = [...inputs, ...outputs]
          .filter(Form.undestroyed)
          .map((i) => i.variant.id);

        const filteredOptions = warehouseId
          ? options.filter(
              (o) => o.variant && !addedVariantIds.includes(o.variant.id)
            )
          : [];

        const maxAmount =
          inputs.length == 1 ? sumBy(inputs, "totalAmount") : undefined;

        const outputColumns: ColumnsType<WorkOrderVariant> = [
          {
            title: (
              <FormattedMessage
                id="workOrders.processOutputs.output"
                defaultMessage="Processing Result"
              />
            ),
            render: (_, v) => formatVariantLink(v.variant),
          },
        ];

        if (builder.isDataIntake) {
          outputColumns.push({
            title: <FormattedMessage id="quantity" />,
            width: 200,
            render: (_, v, index) =>
              builder.isReadonly ? (
                formatUnitValue(v.totalAmount, v.unit)
              ) : (
                <Form.Item
                  compact
                  name={["processingOutputs", index, "totalAmount"]}
                  rules={[
                    Rules.gtZero,
                    RuleBuilder.ltEq(
                      inputs[0]?.unit?.id == v.unit.id ? maxAmount : undefined
                    ),
                  ]}
                >
                  <InputNumber step={0.1} min={0} addonAfter={v.unit.abbr} />
                </Form.Item>
              ),
          });

          const metricCols: ColumnsType<WorkOrderVariant> =
            workOrder.activity.activityMetrics
              .filter((m) => m.scope == ActivityMetricScope.Output)
              .map((m) => ({
                title: (
                  <>
                    {m.metric.name} {formatInfoTooltip(m.metric.description)}
                  </>
                ),
                width: "12rem",
                render: (_, o, index) => {
                  const name = ["processingOutputs", index, m.metric.id];

                  if (builder.isReadonly) {
                    return formatUnitValue(
                      builder.form.getFieldValue(name),
                      m.metric.unit
                    );
                  }

                  return (
                    <Form.Item name={name} rules={[Rules.gtEqZero]} compact>
                      <InputNumber
                        step={0.1}
                        min={0}
                        onChange={(val) =>
                          builder.outputs.metrics.debouncedDistributeIndividualMetrics(
                            m.metric.id,
                            o.variant.id,
                            val
                          )
                        }
                        addonAfter={m.metric.unit.abbr}
                      />
                    </Form.Item>
                  );
                },
              }));

          outputColumns.push(...metricCols);
        }

        return (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <TableInput
                disabled={builder.isReadonly}
                name="processingInputs"
                dataSource={inputs}
                rowKey={(o) => o.variant.id}
                tableSelectProps={{
                  mode: "multiple",
                  options: searchByItems ? filteredOptions : workOrderOptions,
                  onSearch: searchByItems ? search : workOrderSearch,
                  placeholder: formatPlaceholder({
                    id: "workOrders.processOutputs.input",
                  }),
                  dropdownRender: variantSelectDropdown({
                    left: (
                      <FormattedMessage
                        id="workOrders.processOutputs.dropdown"
                        defaultMessage="Producible Variants & Categories"
                      />
                    ),
                    right: <FormattedMessage id="quantity" />,
                  }),
                  afterAdd: (selected: WorkOrderOutput[]) => {
                    if (searchByItems) return;

                    builder.outputs.addProcessingInputs(selected);
                  },
                  entityById: entityById({
                    sourceWarehouseId: warehouseId,
                    returnedAmount: 0,
                  }),
                  prefix: ({ setSelectedItems }) => (
                    <SelectField
                      style={{ minWidth: "150px" }}
                      onChange={(value) => {
                        setSelectorConfig(value);
                        setSelectedItems([]);
                      }}
                      showSearch={false}
                      allowClear={false}
                      defaultValue={selectorConfig}
                      options={[
                        {
                          key: "item",
                          label: (
                            <FormattedMessage
                              id="select.variants.allProducts"
                              defaultMessage="All Products"
                            />
                          ),
                        },
                        {
                          key: "harvestOrder",
                          label: (
                            <FormattedMessage
                              id="select.variants.byHarvestOrder"
                              defaultMessage="Harvest Outputs"
                            />
                          ),
                        },
                        {
                          key: "processingOrder",
                          label: (
                            <FormattedMessage
                              id="select.variants.byProcessingOrder"
                              defaultMessage="Processing Outputs"
                            />
                          ),
                        },
                      ]}
                    />
                  ),
                }}
                tableProps={{
                  scroll: undefined,
                  expandable:
                    builder.isReadonly &&
                    inputs.filter((i) => i.sources?.length).length == 0
                      ? undefined
                      : {
                          rowExpandable: (i) =>
                            !i._destroy && !!i.sources?.length,
                          expandedRowRender: (i, index) => (
                            <TableInput
                              disabled={builder.isReadonly}
                              name={["processingInputs", index, "sources"]}
                              dataSource={i.sources || []}
                              tableProps={{ size: "small" }}
                              onRemove={() =>
                                builder.outputs.recalculateProcessingInput(
                                  index
                                )
                              }
                              rowKey={(s) =>
                                s.id || s.sourceWorkOrderVariant.id
                              }
                              columns={[
                                {
                                  title: (
                                    <FormattedMessage id="workOrders.entityName" />
                                  ),
                                  render: (_, s) =>
                                    formatDetailsLink({
                                      id: s.sourceWorkOrderVariant.workOrder.id,
                                      route: routes.agro.workOrders.details,
                                    }),
                                },
                                {
                                  title: (
                                    <FormattedMessage id="activities.entityName" />
                                  ),
                                  render: (_, s) =>
                                    formatActivity(
                                      s.sourceWorkOrderVariant.workOrder
                                        .activity.name,
                                      s.sourceWorkOrderVariant.workOrder
                                        .cycleNumber
                                    ),
                                },
                                {
                                  title: (
                                    <FormattedMessage id="workOrders.documentDate" />
                                  ),
                                  render: (_, s) =>
                                    formatDate(
                                      s.sourceWorkOrderVariant.workOrder
                                        .documentDate
                                    ),
                                },
                                {
                                  title: <FormattedMessage id="quantity" />,
                                  render: (_, s) =>
                                    formatUnitValue(s.amount, i.unit),
                                },
                              ]}
                            />
                          ),
                        },
                }}
                columns={[
                  {
                    title: (
                      <FormattedMessage
                        id="workOrders.processOutputs.input"
                        defaultMessage="Output to Process"
                      />
                    ),
                    render: (_, v) => formatVariantLink(v.variant),
                  },
                  {
                    title: (
                      <FormattedMessage id="inventoryRequests.currentStock" />
                    ),
                    align: "center",
                    render: (_, v) =>
                      formatUnitValue(v.stock?.onHand, v.variant.variationUnit),
                  },
                  {
                    title: <FormattedMessage id="quantity" />,
                    width: 200,
                    render: (_, v, index) =>
                      builder.isReadonly ? (
                        formatUnitValue(v.totalAmount, v.unit)
                      ) : (
                        <Form.Item
                          compact
                          name={["processingInputs", index, "totalAmount"]}
                          rules={[
                            Rules.gtZero,
                            ({ getFieldValue }) => ({
                              validator() {
                                const variant = getFieldValue([
                                  "processingInputs",
                                  index,
                                ]) as WorkOrderOutput;
                                if (!variant._destroy) {
                                  if (variant.totalAmount == 0)
                                    return Promise.reject(
                                      <FormattedMessage
                                        id="form.validation.gtEqZero"
                                        defaultMessage="gtEqZero"
                                      />
                                    );

                                  if (
                                    variant.totalAmount >
                                    (variant.stock?.onHand || 0)
                                  )
                                    return Promise.reject(
                                      <FormattedMessage id="stock.unsufficient" />
                                    );
                                }

                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <InputNumber
                            step={0.1}
                            min={0}
                            addonAfter={v.unit.abbr}
                            disabled={!!v.sources?.length}
                            onChange={() => {
                              setFieldValue(
                                ["processingInputs", index, "returnedAmount"],
                                0
                              );
                            }}
                          />
                        </Form.Item>
                      ),
                  },
                ]}
              />
            </Col>
            <Col span={24} style={{ textAlign: "center" }}>
              <ArrowDownOutlined />
            </Col>
            <Col span={24}>
              <TableInput
                disabled={builder.isReadonly}
                name="processingOutputs"
                dataSource={outputs}
                rules={
                  builder.isDataIntake && inputs.length > 0
                    ? [Rules.required]
                    : undefined
                }
                rowKey={(o) => o.variant.id}
                tableSelectProps={{
                  mode: "multiple",
                  options: filteredOptions,
                  onSearch: search,
                  placeholder: formatPlaceholder({
                    id: "workOrders.processOutputs.output",
                  }),
                  dropdownRender: variantSelectDropdown({
                    left: (
                      <FormattedMessage id="workOrders.processOutputs.dropdown" />
                    ),
                  }),
                  entityById: entityById({
                    returnWarehouseId: warehouseId,
                  }),
                }}
                columns={outputColumns}
              />
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
}

export function WorkOrderProcessOutputs() {
  const { builder } = useContext(WorkOrderContext);

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdateBy((val) => val.costCenters)}>
      {() => {
        const cc = builder.costCenters.get(true)[0];

        return (
          <WarehouseOutputs warehouseId={cc?.infrastructure?.warehouse?.id} />
        );
      }}
    </Form.Item>
  );
}
