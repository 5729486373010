import { FormattedMessage, useIntl } from "react-intl";
import {
  useCropFieldOptions,
  useCropFieldWithStagesOptions,
  useCurrentUser,
} from "../../../lib/hooks";
import {
  UnitType,
  CropFieldsQueryVariables,
  CropFieldProgressFragment,
  CropFieldFragment,
  UnitShortFragment,
} from "../../../lib/graphql";
import {
  formatUnitValue,
  formatProgress,
  formatCropField,
  formatCropFieldArea,
} from "../../../lib/formats";
import { Form, Rules, InputNumber } from "../../form";
import { ReactNode, useContext, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { Spin } from "antd";
import { WorkOrderContext } from "./WorkOrderContext";
import { useUpdateEffect } from "react-use";
import { WorkOrderBuilder, WorkOrderFormValues } from "./builder";
import { WorkOrderCostCenters } from "./costCenters";
import { WorkOrderCostCenter } from "./builder/costCenters";
import { filterFalse } from "../../../lib/utils";

function useCropFieldProgressUpdater(
  enabled: boolean,
  variables: CropFieldsQueryVariables
) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { load } = useCropFieldOptions({ variables });
  const [progressUpdating, setProgressUpdating] = useState(false);

  useUpdateEffect(() => {
    if (!enabled) return;

    const id = workOrder.costCenters.map((cc) => cc.cropField?.id || "");
    if (!id.length) return;

    setProgressUpdating(true);

    load({
      variables: {
        filter: {
          id,
        },
      },
    })
      .then((result) => result.data?.cropFields?.items || [])
      .then((cropFields) => {
        builder.costCenters.updateProgress(cropFields);
        setProgressUpdating(false);
      })
      .catch(() => setProgressUpdating(false));
  }, [workOrder.activity.id, workOrder.cycleNumber]);

  return { progressUpdating };
}

function buildCropFieldVariables(
  showCycleProgress: boolean,
  isHarvest: boolean,
  workOrder: WorkOrderFormValues
): CropFieldsQueryVariables {
  return {
    filter: {
      group: workOrder.cropFieldGroup ? [workOrder.cropFieldGroup] : undefined,
      localityId: [workOrder.locality.id],
      cropId: workOrder.cropCycle
        ? workOrder.cropCycle.crops.map((c) => c.id)
        : undefined,
    },
    progressFilter:
      showCycleProgress || isHarvest
        ? {
            activityId: workOrder.activity.id,
            localityId: workOrder.locality.id,
            cycleNumber: workOrder.cycleNumber,
            cropCycleId: workOrder.cropCycle?.id,
          }
        : undefined,
    estimationInput: isHarvest
      ? { cropCycleId: workOrder.cropCycle?.id }
      : undefined,
  };
}

function cropFieldToEntity(
  cropField: CropFieldProgressFragment,
  builder: WorkOrderBuilder
) {
  return {
    id: "",
    cropField,
    dayGoal: builder.costCenters.calculateDayGoal(
      cropField,
      cropField.plannedProgress,
      cropField.actualProgress
    ),
    profitableId: cropField.id,
    profitableName: cropField.name,
    actualProgress: cropField.actualProgress,
    plannedProgress: cropField.plannedProgress,
    estimatedHarvestAmount: cropField.estimatedHarvestAmount,
    totalProgress: 0,
    employees: [],
    machines: [],
    variants: [],
    metrics: builder.costCenters.metrics.build(),
    costCenter: cropField.costCenter,
    progressPercent: 0,
  };
}

function formatCropFieldOption(
  content: ReactNode,
  showCycleProgress: boolean,
  isHarvest: boolean,
  progressUnit: UnitShortFragment,
  cropField?: CropFieldProgressFragment
) {
  if (!cropField) return content;

  return (
    <>
      {content}{" "}
      {showCycleProgress
        ? formatProgress(
            cropField.plannedProgress || 0,
            progressUnit,
            cropField.actualProgress,
            {
              style: { width: "30%" },
            }
          )
        : isHarvest && cropField.estimatedHarvestAmount
        ? formatProgress(
            cropField.estimatedHarvestAmount,
            progressUnit,
            cropField.actualProgress,
            { supressOverload: true, style: { width: "30%" } }
          )
        : null}
    </>
  );
}

export function WorkOrderCropFields({
  byPercentage,
}: {
  byPercentage?: boolean;
}) {
  const intl = useIntl();
  const { currentTenant } = useCurrentUser();
  const { workOrder, builder } = useContext(WorkOrderContext);

  const progressUnit = workOrder.activity.progressUnit;
  const isAreaUnit = progressUnit.unitType === UnitType.Area;
  const isPlant =
    progressUnit.abbr == intl.formatMessage({ id: "plants.abbr" });
  const showCycleProgress = builder.isAgricultural && (isAreaUnit || isPlant);
  const variables = buildCropFieldVariables(
    showCycleProgress,
    builder.isHarvest,
    workOrder
  );

  const { progressUpdating } = useCropFieldProgressUpdater(
    showCycleProgress || builder.isHarvest,
    variables
  );

  const columns: ColumnsType<WorkOrderCostCenter> = filterFalse([
    {
      title: <FormattedMessage id="cropFields.entityName" />,
      dataIndex: "cropField",
      render: (_, c, index) => (
        <Form.Item name={["costCenters", index, "id"]} compact>
          {formatCropField(c.cropField)}
        </Form.Item>
      ),
    },
    !builder.isDataIntake && {
      title: workOrder.activity.useTotalArea ? (
        <FormattedMessage id="cropFields.totalArea" />
      ) : (
        <FormattedMessage id="cropFields.effectiveArea" />
      ),
      width: "12rem",
      align: "center",
      render: (_, f) =>
        formatCropFieldArea(
          f.cropField,
          workOrder.activity.useTotalArea,
          currentTenant.features.customCropVarieties
        ),
    },
    showCycleProgress
      ? {
          title: <FormattedMessage id="progress" />,
          dataIndex: ["cropFieldProgress"],
          width: "24rem",
          align: "center",
          render: (_, f) =>
            f.cropField && progressUpdating ? (
              <Spin />
            ) : (
              formatProgress(f.plannedProgress, progressUnit, f.actualProgress)
            ),
        }
      : builder.isHarvest
      ? {
          title: <FormattedMessage id="harvestEstimates.estimatedHarvest" />,
          render: (_, f) =>
            progressUpdating ? (
              <Spin />
            ) : (
              f.estimatedHarvestAmount &&
              formatProgress(
                f.estimatedHarvestAmount,
                progressUnit,
                f.actualProgress,
                { supressOverload: true }
              )
            ),
        }
      : builder.isCompleted && builder.showDayGoal
      ? {
          title: <FormattedMessage id="progress" />,
          width: "24rem",
          align: "center",
          render: (_, f) =>
            f.cropField &&
            formatProgress(
              f.dayGoal,
              workOrder.activity.progressUnit,
              f.totalProgress
            ),
        }
      : null,
    builder.showDayGoal &&
      !builder.isDataIntake && {
        title: (
          <FormattedMessage id="workOrders.dayGoal" defaultMessage="dayGoal" />
        ),
        dataIndex: "dayGoal",
        width: "10rem",
        render: (_, f, index) =>
          builder.isReadonly || builder.isInProgress ? (
            formatUnitValue(f.dayGoal, progressUnit)
          ) : (
            <Form.Item
              name={["costCenters", index, "dayGoal"]}
              rules={f._destroy ? undefined : [Rules.gtZero]}
              compact
            >
              <InputNumber
                step={0.1}
                min={0}
                addonAfter={progressUnit.abbr}
                onChange={() => builder.costCenters.onDayGoalChanged()}
              />
            </Form.Item>
          ),
      },
  ]);

  return (
    <WorkOrderCostCenters
      byPercentage={byPercentage}
      tableSelectProps={{
        mode: "multiple",
        showGroups: true,
        placeholder: (
          <FormattedMessage id="select.cropField" defaultMessage="cropFields" />
        ),
        formatOption: (content, { cropField }) =>
          formatCropFieldOption(
            content,
            showCycleProgress,
            builder.isHarvest,
            progressUnit,
            cropField
          ),

        optionsHook: useCropFieldWithStagesOptions,
        optionsHookParams: {
          variables,
          useTotalArea: workOrder.activity.useTotalArea,
        },
        entityById: (_, { cropField, cropFieldLoader }) => {
          if (cropField) return cropFieldToEntity(cropField, builder);
          if (cropFieldLoader)
            return cropFieldLoader().then((cfs: CropFieldFragment[]) =>
              cfs.map((cf) => cropFieldToEntity(cf, builder))
            );
        },
      }}
      columns={columns}
    />
  );
}
