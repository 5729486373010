import {
  CropStageChangeEvent,
  CropStageChanges,
  CropStageFields,
  CropStageFragment,
  CropStageType,
} from "../../../lib/graphql";
import {
  evictCache,
  useCropStageChangeEventOptions,
  useCropStageCreate,
  useCropStageOptions,
  useCropStageTypeOptions,
  useCurrentUser,
  useLocaleNameField,
  useLocalityOptions,
} from "../../../lib/hooks";
import { ItemSidebar, NewForm } from "../../shared";
import { ItemFieldFormConfig } from "../../shared/ListView/ItemForm";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { LocaleEmoji, TranslationsInput } from "../../users";
import { DatePicker, Form, Rules, SelectField } from "../../form";
import {
  formatEntityNameLabel,
  formatPlaceholder,
  shouldUpdate,
} from "../../../lib/formats";
import { Col, Input, Row } from "antd";
import { CostCenterSelect } from "../../finance";
import { CropSelect } from "../CropSelect";

interface CropStageSidebarProps {
  onSave: (result: CropStageFragment | null | undefined) => void;
}

interface NewCropStageFormProps {
  entityName: string;
  onClose: () => void;
  onSave?: CropStageSidebarProps["onSave"];
}

const messages = defineMessages({
  new: { id: "new.header", defaultMessage: "new" },
  cropStage: { id: "cropStages.entityName", defaultMessage: "Crop Stage" },
  home: { id: "home", defaultMessage: "home" },
  cropStages: {
    id: "cropStages",
    defaultMessage: "cropStages",
  },
  description: {
    id: "cropStages.description",
    defaultMessage: "description",
  },
  type: {
    id: "type",
    defaultMessage: "type",
  },
  prev: {
    id: "cropStages.prevStage",
    defaultMessage: "prevStage",
  },
  changeEvent: {
    id: "cropStages.changeEvent",
    defaultMessage: "changeEvent",
  },
  changeDate: { id: "cropStages.changeDate", defaultMessage: "Change Date" },
  pageTitle: { id: "cropStages.pageTitle", defaultMessage: "Crop Stagess" },
  created: { id: "created", defaultMessage: "created" },
  costCenter: { id: "costCenters.entityName", defaultMessage: "costCenter" },
});

const RenderLocalities = () => {
  const intl = useIntl();

  return (
    <Form.Item
      key="cropStageLocalitiesAttributes"
      noStyle
      shouldUpdate={shouldUpdate("cropStageLocalitiesAttributes")}
    >
      {({ getFieldValue }) => (
        <div style={{ marginBottom: 16 }}>
          <Row align="middle" gutter={[2, 8]} style={{ marginBottom: 2 }}>
            <Col span={11}>
              <FormattedMessage id="localities" />
            </Col>
            <Col span={13}>
              <FormattedMessage id="costCenters" />
            </Col>

            {!getFieldValue("cropStageLocalitiesAttributes").filter(
              Form.undestroyed
            ).length ? (
              <>
                <Col span={11}>
                  <Input
                    readOnly
                    value={intl.formatMessage({ id: "localities.all" })}
                  />
                </Col>
                <Col span={13}>
                  <Form.Item name="costCenterId" compact>
                    <CostCenterSelect
                      placeholder={formatPlaceholder({
                        id: "costCenters.entityName",
                      })}
                    />
                  </Form.Item>
                </Col>
              </>
            ) : null}
          </Row>

          <Form.List
            name="cropStageLocalitiesAttributes"
            hideRemoveLabel
            addText={
              <FormattedMessage
                id="cropStages.addLocality"
                defaultMessage="Add Specific Farm"
              />
            }
            renderItem={({ key, name: index }, removeIcon) => (
              <Row key={key} align="top" gutter={2} style={{ marginBottom: 2 }}>
                <Col span={11}>
                  <Form.Item
                    compact
                    name={[index, "localityId"]}
                    rules={[Rules.required]}
                  >
                    <SelectField
                      optionsHook={useLocalityOptions}
                      placeholder={formatPlaceholder({
                        id: "localities.entityName",
                      })}
                    />
                  </Form.Item>
                </Col>

                <Col span={11}>
                  <Form.Item compact name={[index, "costCenterId"]}>
                    <CostCenterSelect
                      placeholder={formatPlaceholder({
                        id: "costCenters.entityName",
                      })}
                    />
                  </Form.Item>
                </Col>

                <Col span={2}>{removeIcon}</Col>
              </Row>
            )}
          />
        </div>
      )}
    </Form.Item>
  );
};

export function useCropStageFormFields() {
  const intl = useIntl();
  const localeNameField = useLocaleNameField();
  const { currentTenant } = useCurrentUser();

  return [
    {
      label: formatEntityNameLabel(
        <FormattedMessage
          id="cropStages.entityName"
          defaultMessage="cropStage"
        />
      ),
      name: localeNameField,
      type: "string",
      prefix: <LocaleEmoji.Current />,
      rules: [Rules.required],
    },
    {
      label: intl.formatMessage(messages.type),
      name: "kind",
      type: "select",
      rules: [Rules.required],
      optionsHook: useCropStageTypeOptions,
    },
    {
      label: intl.formatMessage(messages.prev),
      name: "prevStageId",
      type: "select",
      showGroups: true,
      optionsHook: useCropStageOptions,
      optionsHookFilter: (c) => c.group,
    },
    {
      label: intl.formatMessage(messages.changeEvent),
      name: "changeEvent",
      type: "select",
      rules: [Rules.required],
      optionsHook: useCropStageChangeEventOptions,
    },
    {
      type: "custom",
      render: () => (
        <Form.Item
          key="changeDate"
          noStyle
          shouldUpdate={shouldUpdate("changeEvent")}
        >
          {({ getFieldValue }) => {
            if (getFieldValue("changeEvent") != CropStageChangeEvent.Date)
              return null;

            return (
              <Form.Item
                name="changeDate"
                label={intl.formatMessage(messages.changeDate)}
                rules={[Rules.required]}
              >
                <DatePicker />
              </Form.Item>
            );
          }}
        </Form.Item>
      ),
    },
    {
      type: "custom",
      hidden: !currentTenant.features.costCenters,
      render: RenderLocalities,
    },
    {
      label: <FormattedMessage id="crops" />,
      name: "cropIds",
      formItemProps: {
        extra: (
          <FormattedMessage
            id="cropStages.cropsHelp"
            defaultMessage="cropsHelp"
          />
        ),
      },
      type: "custom",
      render: () => (
        <CropSelect
          mode="multiple"
          placeholder={<FormattedMessage id="crops.all" />}
        />
      ),
    },
  ] as ItemFieldFormConfig<CropStageFields | CropStageChanges>[];
}

export function NewCropStageForm({
  entityName,
  onSave,
  onClose,
}: NewCropStageFormProps) {
  const fields = useCropStageFormFields();

  return (
    <NewForm
      entityName={entityName}
      mutation={useCropStageCreate}
      onMutate={(result) => {
        if (onSave) onSave(result.cropStageCreate?.result);
        return result.cropStageCreate;
      }}
      mutationOptions={{ update: evictCache("cropStages") }}
      formValues={() =>
        ({
          kind: CropStageType.Production,
          name: "",
          changeEvent: CropStageChangeEvent.Manual,
          cropStageLocalitiesAttributes: [],
        } as CropStageFields)
      }
      onClose={onClose}
      fields={[
        ...fields,
        {
          type: "custom",
          render: TranslationsInput.renderName,
        },
      ]}
    />
  );
}

export function NewCropStageSidebar({ onSave }: CropStageSidebarProps) {
  const intl = useIntl();

  return (
    <ItemSidebar
      item={null}
      sidebarActions={{
        newCropStage: ({ closeSidebar }) => (
          <NewCropStageForm
            entityName={intl.formatMessage(messages.new, {
              entityName: intl.formatMessage(messages.cropStage),
            })}
            onSave={onSave}
            onClose={closeSidebar}
          />
        ),
      }}
    />
  );
}
